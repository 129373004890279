.login-site {
  background-color: $white;
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: absolute;

  &::before {
    $size: 350px;
    content: '';
    position: absolute;
    left: -$size;
    bottom: -$size;
    width: $size * 2;
    height: $size * 2;
    background-image: url('../../images/circle-lines-white.svg');
    background-size: contain;
  }

  &__content {
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 10vw;
    padding-right: 16vw;
    overflow: auto;
  }

  &__logo {
    height: 108px;
    margin-top: 60px;
    margin-bottom: 70px;
  }

  &__aside {
    width: 40vw;
  }
}

.login-aside {
  background: linear-gradient(219deg, #00a1ed, #2968bc, #4c3892, #5a2683);
  color: $white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;

  h2 {
    font-size: 40px;
    color: #fff;
    font-weight: 700;
    z-index: 1;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 295px;
    height: 297px;
    background-image: url('../../images/circle-lines.svg');
    background-size: contain;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -3vw;
    right: -8vw;
    width: 30vw;
    height: 30vw;
    background-image: url('../../images/circle-dots.svg');
    background-size: contain;
  }

  &__roadmap {
    position: relative;
    width: 130%;
    left: -25%;
    margin: 50px 0;
    display: flex;

    &::before {
      content: '';
      position: absolute;
      left: -5%;
      top: 20%;
      width: 16vw;
      height: 16vw;
      border-radius: 50%;
      background: linear-gradient(180deg, #00a1ed, #2968bc, #4c3892, #5a2683);
    }

    img {
      position: relative;
      z-index: 2;
    }
  }

  &__owner {
    width: 80%;
    font-size: 0.7em;
    text-align: left;
    position: relative;
    z-index: 1;

    p {
      font-size: 22px;
    }
  }
}

.login-aside-owner__contact {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;

  &-wrapper {
    display: flex;

    span {
      margin-left: 5px;
    }
  }
}

.login-aside-roadmap__left {
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 40%;
  background-color: #312280;
  border-radius: 20px;
  padding: 1em;
  justify-content: space-between;

  div {
    height: 65px;
    margin: 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:first-child {
      margin: 0 0 15px 0;
    }

    &:last-child {
      margin: 15px 0 0 0;
    }
  }

  span {
    height: 12px;
    width: 12px;
    background-color: #ffffff;
    border-radius: 50%;
  }
}

.login-aside-roadmap__unique-dot {
  background-color: #0071c5 !important;
}

.login-aside-roadmap__right {
  display: flex;
  flex-direction: column;
  padding: 1em;
  justify-content: space-between;

  div {
    margin: 15px 0;
    height: 65px;
    display: flex;
    align-items: center;

    &:first-child {
      margin: 0 0 15px 0;
    }

    &:last-child {
      margin: 15px 0 0 0;
    }
  }

  span {
    z-index: 1;
    font-size: 22px;
  }
}

@media (max-width: 1450px) {
  .login-aside-owner__contact {
    flex-direction: column;

    &-wrapper {
      margin: 5px 0;
    }
  }
}

@media (max-width: 1025px) {
  .login-site {
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow-y: auto;

    &__content {
      width: 100%;
      min-height: 100vh;
      // height: 1000px !important;
    }

    &__aside {
      width: 100%;

      &::after {
        bottom: 0;
      }
    }
  }

  .login-aside {
    &__roadmap {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 0;
      }
    }

    &-roadmap__left {
      margin: 0 20px 0 20%;
    }
  }
}
