$black: #000000;
$black-dark: #22222f;
$black-selected: #1d1d27;
$shadow: #00000029;
$black-light: #ffffff;
$blue-light: #ffffff;
$blue-light2: #ffffff;

$grey: #636166;
$grey-2: #bfbfbf;
$text-grey: #6e6c71;
$text-grey2: #c6c6c6;
$grey-light: #ffffff;
$border-grey: #70707020;
$border-grey-light: #dbdbdb;
$black-border: rgba(37, 37, 51, 0.2);

$white: #fff;
$white-light: rgba(255, 255, 255, 0.2);

$red: #f40c0c;
$orange: #ff6a08;
$orange-light: rgba(255, 106, 8, 0.12);
$green: #3be548;
$yellow: #ffaa37;
$blue: #00aeed;
$purple: #7020ff;

$xiaomi: #00aeed;
$xsummit: #14c435;
$xiaomi-light: #baeafc;
$secondary-color: linear-gradient(104deg, #450b3e 0%, #090546 100%);
$spotify: #1ed760;
$tesla: #e82127;
$intel: #0071c5;

$lightGreen: #61e18e;
